<template>
  <section class="content">
    <div class="text">
      <h1>
        Offers
        <div class="filter" v-if="categories.length">
          Filter:
          <select v-model="filter">
            <option value>All</option>
            <option :key="cat" v-for="cat in categories" values="cat">{{ cat }}</option>
          </select>
        </div>
      </h1>
      <span v-html="page.main_text"></span>

      <offers-elem :offers="filteredOffers"></offers-elem>
    </div>
  </section>
</template>

<script>
import OffersElem from "@/components/Offers.vue"
import api from "@/services/api"

export default {
  metaInfo() {
    return {
      title: this.$store.state.page.title || "South Yorkshire Police Federation",
      meta: [
        { name: "description", content: this.$store.state.page.meta_desc },
        { name: "keywords", content: this.$store.state.page.meta_keywords }
      ]
    }
  },
  components: {
    OffersElem
  },
  created() {
    api.getPageByURL(this.$route.path)
  },
  data() {
    return { filter: "" }
  },
  computed: {
    page() {
      return this.$store.state.page
    },
    categories() {
      if (!this.page.offers) return []
      if (!this.page.offers.length) return []

      return this.page.offers.map(o => o.category).filter((x, i, a) => a.indexOf(x) == i)
    },
    filteredOffers() {
      if (!this.page.offers) return []
      if (!this.filter) return this.page.offers
      return this.page.offers.filter(o => o.category == this.filter)
    }
  }
}
</script>

<style scoped>
.filter {
  float: right;
  font-size: 1.5rem;
  margin-top: -0.5rem;
}
.filter select {
  font-size: 1.5rem;
  padding: 0.5rem 1rem;
  margin-left: 1rem;
}
@media screen and (min-width: 1050px) {
  .filter {
    font-size: 2rem;
  }
  .filter select {
    font-size: 2rem;
  }
}
</style>
