<template>
  <section class="profiles">
    <div class="grid">
      <a href :key="offer._id" @click.prevent="showOffer(offer)" v-for="offer in offers">
        <div class="img">
          <img :src="offer.image" alt="Name" />
        </div>
        <div class="caption">
          <span class="profile-name">{{ offer.name }}</span>
          <span class="profile-title">{{ offer.category }}</span>
        </div>
      </a>
    </div>

    <modal :scrollable="true" height="auto" name="offers" width="90%">
      <a @click.prevent="$modal.hide('offers')" class="modal-close" href>
        <font-awesome-icon icon="times" />
      </a>
      <div class="modal-grid">
        <div>
          <h2>{{ offerToShow.name }}</h2>
          <p>
            <strong>{{ offerToShow.title }}</strong>
          </p>
          <div v-html="offerToShow.description"></div>

          <button class="button" v-if="offerToShow.file" @click="openDoc(offerToShow.file)">
            <font-awesome-icon class="icon" icon="cloud-download-alt" />DOWNLOAD LEAFLET
          </button>
        </div>
        <div class="images">
          <img :src="offerToShow.image" :alt="offerToShow.name" />
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
export default {
  props: ["offers"],
  data: function() {
    return {
      offerToShow: {},
      offersarray: [
        {
          id: 1,
          name: "Firth Financial",
          type: "Financial Advice",
          image: "Firth Financial.jpg",
          description:
            "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>"
        },
        {
          id: 2,
          name: "Hanson",
          type: "Mortgages",
          image: "hansonlogo.gif",
          description:
            "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>"
        },
        {
          id: 3,
          name: "Firth Financial",
          type: "Private Healthcare",
          image: "Patient J V Still GRAND FINALE.png",
          description:
            "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>"
        },
        {
          id: 4,
          name: "Alton Towers",

          type: "Days Out",
          image: "Alton Towers.jpg",
          description:
            "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>"
        },
        {
          id: 5,
          name: "o2",
          type: "Mobiles",
          image: "o2-logo.jpg",
          description:
            "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>"
        },
        {
          id: 6,
          name: "Vodafone",
          type: "Mobiles",
          image: "voda.jpg",
          description:
            "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>"
        },
        {
          id: 7,
          name: "BMW",
          type: "Motoring",
          image: "bmw_logo.jpg",
          description:
            "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>"
        },
        {
          id: 8,
          name: "Kingfisher",

          type: "Accountancy",
          image: "Kingfisher LOGO.jpg",
          description:
            "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>"
        }
      ]
    }
  },
  methods: {
    showOffer(offer) {
      this.offerToShow = offer
      this.$modal.show("offers")
    },
    openDoc(doc) {
      window.open(doc)
    }
  }
}
</script>

<style scoped>
.profiles {
  padding: 5rem 0;
  font-size: 1.8rem;
  line-height: 1.8;
  padding-top: 3rem;
}
.img {
  background: white;
  display: flex;
  align-items: center;
  height: 20rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
.img img {
  display: block;
  margin: auto;
  max-height: 80%;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 3rem;
}
.modal-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 5rem;
  padding-bottom: 3rem;
}
.modal-grid a {
  color: #db411d;
}
.images {
  padding-top: 9rem;
}

.modal-grid >>> h1 {
  padding: 0;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .modal-grid {
    display: table;
    width: 100%;
  }
  .modal-grid > * {
    float: left;
    width: 67%;
    margin-right: 3%;
  }
  .modal-grid > *:last-child {
    width: 30%;
    margin-right: 0;
  }
  .grid {
    display: table;
    width: 100%;
  }
  .grid > * {
    float: left;
    width: 22%;
    margin-right: 3%;
    margin-bottom: 5rem;
  }
}

.grid a {
  position: relative;
  line-height: 0;
  text-decoration: none;
}

.grid a img {
  transition: all 0.3s;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.caption {
  width: 100%;
  background-color: #203165;
  line-height: 1.2;
  color: white;
  padding: 2rem;
  transition: all 0.3s;
  text-align: center;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.grid a:hover .caption {
  background-color: #db411d;
}

.caption span {
  display: block;
}

.profile-title {
  opacity: 0.5;
}

.grid a:hover img {
  filter: brightness(1.05);
}

@media screen and (max-width: 1100px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .images {
    padding-top: 4.5rem;
  }
}
@media screen and (max-width: 850px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .modal-grid {
    grid-template-columns: 1fr;
    grid-gap: rem;
  }
  .images {
    padding-top: 0;
  }
}
@media screen and (max-width: 500px) {
  .grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
